import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import PlacesAutocomplete from 'react-places-autocomplete';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import { eventTypes } from '../../../helpers/nomenclators';
import { GoogleMapLibrary } from '../../../components';

class EventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {}
    };
  }

  componentWillMount() {
    let { event } = this.props;
    this.setState({ event });
  }

  cleanModal() {
    this.setState({
      titleValid: null,
      startValid: null,
      endValid: null,
      textValid: null,
      addressValid: null,
      typeValid: null,
      event: {}
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { event } = this.state;

    this.setState(ps => {
      return {
        ...ps,
        event: { ...event, [name]: value },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      };
    });
  }

  validate() {
    let title = this.title;
    let start = this.start;
    let end = this.end;
    let text = this.text;
    let address = this.address;

    this.setState({
      [title.attributes.getNamedItem('namevalid').value]: title.validity.valid
        ? 'has-success'
        : 'has-danger',
      [start.attributes.getNamedItem('namevalid').value]: start.validity.valid
        ? 'has-success'
        : 'has-danger',
      [end.attributes.getNamedItem('namevalid').value]: end.validity.valid
        ? 'has-success'
        : 'has-danger',
      [text.attributes.getNamedItem('namevalid').value]: text.validity.valid
        ? 'has-success'
        : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      title.validity.valid &&
      start.validity.valid &&
      end.validity.valid &&
      text.validity.valid &&
      address.validity.valid
    );
  }

  onSaveEvent() {
    let { event } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(event);
    }
  }

  render() {
    let { event } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {event && event._id ? t('Edit Event') : t('New Event')}
        </ModalHeader>
        <ModalBody>
          {event ? (
            <div>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.titleValid
                    }
                  >
                    <Label>
                      <Trans>Title</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.title = node)}
                      namevalid="titleValid"
                      value={event.title || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'title',
                          'titleValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.startValid
                    }
                  >
                    <Label>
                      <Trans>Start Date</Trans>
                    </Label>
                    <Datetime
                      locale={'fr'}
                      value={moment(event.start)}
                      inputProps={{
                        ref: node => (this.start = node),
                        namevalid: 'startValid',
                        className: 'form-control datetime-read-only',
                        required: 'required',
                        readOnly: true
                      }}
                      onChange={moment =>
                        this.onChange('start', 'startValid', moment, !!moment)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.endValid
                    }
                  >
                    <Label>
                      <Trans>End Date</Trans>
                    </Label>
                    <Datetime
                      isValidDate={c => {
                        let end = moment(c);
                        let start = moment(event.start);
                        return (
                          end.isSameOrAfter(moment(start, 'DD-MM-YYYY')) ||
                          end.format('DD-MM-YYYY') ===
                            start.format('DD-MM-YYYY')
                        );
                      }}
                      locale={'fr'}
                      value={
                        !event.end ||
                        moment(event.end.toString()).isBefore(
                          moment(event.start, 'DD-MM-YYYY')
                        )
                          ? moment(event.start)
                          : moment(event.end)
                      }
                      inputProps={{
                        ref: node => (this.end = node),
                        namevalid: 'endValid',
                        className: 'form-control datetime-read-only',
                        required: 'required',
                        readOnly: true
                      }}
                      onChange={moment =>
                        this.onChange('end', 'endValid', moment, !!moment)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={'has-label ' + this.state.addressValid}>
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={event.address ? event.address : ''}
                        onChange={address =>
                          this.onChange(
                            'address',
                            'addressValid',
                            address,
                            address !== null && address !== ''
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label>
                      <Trans>Type</Trans>
                    </Label>
                    <Select
                      className="primary first-letter-uppercase"
                      innerRef={node => (this.type = node)}
                      namevalid="typeValid"
                      value={event.type}
                      options={eventTypes.map(p => ({ label: t(p), value: p }))}
                      onChange={event => {
                        this.onChange(
                          'type',
                          'typeValid',
                          event ? event.value : null,
                          !!event
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.textValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.text = node)}
                      namevalid="textValid"
                      value={event.text || ''}
                      onChange={event =>
                        this.onChange(
                          'text',
                          'textValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveEvent()}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(EventModal));
